$( window ).on("load", function() {
    $(document).on("click",".js-mobile-toggle",function() {
        $(this).toggleClass('active')
        $('.js-mobile-menu').toggleClass('active')
    });
    if($('.cookies-popup').length > 0) {
        setTimeout(function(){
            $('.cookies-popup').removeClass('hidden');
        }, 150);

        $('.cookies-popup .cookies-popup__btnHolder--btn').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var thisBtn = $(this);
            var thisID = thisBtn.attr('id');

            var d = new Date();
            d.setTime(d.getTime() + (360 * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();

            if(thisID === 'accept') {
                document.cookie = "cookieAgreement=1;" + expires + ";path=/";
                document.cookie = "cookiesAnalytical=1;" + expires + ";path=/";
            } else if (thisID === 'decline') {
                document.cookie = "cookieAgreement=0;" + expires + ";path=/";
                document.cookie = "cookiesAnalytical=0;" + expires + ";path=/";
            }
            $('.cookies-popup').addClass('hidden');
        });
    }

    $(document).on('click', '.data-link', function (e) {
        e.preventDefault();
        var thisEl = $(this);
        var thisBlank = thisEl.hasClass('blank') ? '_blank' : '_self';
        var thisLink = thisEl.attr('href');
        var thisDataLink = thisEl.attr('data-link');
        var thisDataSection = thisEl.parents('section').attr('data-section') ? thisEl.parents('section').attr('data-section') : 'Header';
        if(thisEl.parents('section').attr('data-section')) {
            var uaDataLink = thisDataLink;
        } else {
            var uaDataLink = thisDataLink+' @ header';
        }
        if(typeof gtag !== "undefined") {
            if((location.host !== 'tesonet-web.stillnot.live') && (location.host !== 'test.tesonet.com')) {
                gtag('event', 'Click', {
                    'event_category' : 'Main Interactions',
                    'event_label' : uaDataLink,
                    'send_to': 'UA-100228991-1',
                });
                gtag('event', 'click', {
                    'element_name': thisDataLink,
                    'element_link': thisLink,
                    'element_type': 'link_click',
                    'section_name': thisDataSection,
                    'send_to': 'G-VSGKRRQ04C'
                });
            }
        }
        window.open(thisLink, thisBlank);
    });


    $('.scroll-top-btn').on('click', function () {
        $('html, body').animate({
            scrollTop: $(".main").offset().top
        }, 600);
    });

    if($('.scrollDownToNextSection').length > 0) {
        $('.scrollDownToNextSection').on('click', function (){
            var thisClick = $(this);
            var thisSection = thisClick.parents('section');
            var nextSection = thisSection.next();
            if(nextSection.length > 0) {
                if(window.devicePixelRatio > 1) {
                    $('html, body').animate({
                        scrollTop: nextSection.offset().top - (($('header').height() * 2) + 60)
                    }, 600);
                } else {
                    $('html, body').animate({
                        scrollTop: nextSection.offset().top - $('header').height()
                    }, 600);
                }
            }
        });
    }

    header_bg();

    $( window ).on("scroll", function() {
        if($(window).width() > 1024) {
            var minus = 341;
        } else if(($(window).width() < 1025) && ($(window).width() > 767)) {
            var minus = 394;
        } else if ($(window).width() < 768) {
            var minus = 300;
        }
        topOfFooter = $('.footer').position().top;
        scrollDistanceFromTopOfDoc = $(document).scrollTop() + $(window).height();
        scrollDistanceFromTopOfFooter = scrollDistanceFromTopOfDoc - minus;

        if (scrollDistanceFromTopOfDoc > topOfFooter) {
            $('.scroll-top-btn').addClass('abs');
        } else  {
            $('.scroll-top-btn').removeClass('abs');
        }

        if($('.scroll-top-btn').length > 0) {
            if(window.scrollY > 300) {
                $('.scroll-top-btn').addClass('show');
            } else {
                $('.scroll-top-btn').removeClass('show');
            }
        }
        if($('.header-nav').length > 0) {
            header_bg();
        }
    });

    function header_bg() {
        if(window.scrollY > 1) {
            $('.header-nav').addClass('bg');
        } else {
            $('.header-nav').removeClass('bg');
        }
    }

});
